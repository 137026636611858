import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private newToast: Subject<string> = new Subject<string>();

  constructor() { }

  createNewToast(text: string): void {
    this.newToast.next(text);
  }

  public toastSubscription(): Observable<string> {
    return this.newToast;
  }
}
