import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {Router} from "@angular/router";
import {ToastService} from "../../toast/toast.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token = ""

  constructor(private httpClient: HttpClient, private router: Router, private toastService: ToastService) {}

  public login(username, password): void {
    this.httpClient.post(environment.backendUrl + "/user/login", {"username":username,"password":password}).subscribe(response => {
      if (!response["access_token"]) {
        this.toastService.createNewToast("Kein Token erhalten, Login fehlgeschlagen. Bitte wenden sie sich an den Seiten Administrator.");
        return;
      }
      this.token = response["access_token"];
    }, error => {
      this.toastService.createNewToast(error.error.errors.password);
    });
  }

  public logout(): void {
    this.router.navigate(['']);
    this.token = "";
  }

  public isLoggedIn(): boolean {
    return this.token != "";
  }

  public getHeadersWithToken(): HttpHeaders {
    return new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Authorization",
      "Authorization": `Bearer ${this.token}`
    });
  }

  public changePassword(username, password, passwordRepeat) {
    this.httpClient.post(environment.backendUrl + "/user/changepassword", {"username":username,"password":password,"password_repeat":passwordRepeat}).subscribe(response => {
      return;
    }, error => {
      this.toastService.createNewToast(error.error.errors.password);
    });
  }
}
