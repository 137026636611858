import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "./services/authentication/auth-guard/auth-guard.service";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'loader',
    pathMatch: 'full'
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'case-list',
    loadChildren: () => import('./pages/case-list/case-list.module').then(m => m.CaseListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'case-request-list',
    loadChildren: () => import('./pages/case-request-list/case-request-list.module').then(m => m.CaseRequestListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'billing-list',
    loadChildren: () => import('./pages/billing-list/billing-list.module').then(m => m.BillingListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'downloads',
    loadChildren: () => import('./pages/downloads/downloads.module').then( m => m.DownloadsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meine-daten',
    loadChildren: () => import('./pages/my-data/my-data.module').then(m => m.MyDataPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'case',
    loadChildren: () => import('./pages/case/case.module').then(m => m.casePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'case-request',
    loadChildren: () => import('./pages/case-request/case-request.module').then(m => m.CaseRequestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then(m => m.HelpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'individual-download',
    loadChildren: () => import('./pages/individual-download/individual-download.module').then( m => m.IndividualDownloadPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'individual-billing',
    loadChildren: () => import('./pages/individual-billing/individual-billing.module').then( m => m.IndividualBillingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sign',
    loadChildren: () => import('./pages/sign/sign.module').then( m => m.SignPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mails',
    loadChildren: () => import('./pages/mails/mails.module').then( m => m.MailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mail',
    loadChildren: () => import('./pages/mail/mail.module').then( m => m.MailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/case/reports/reports.module').then(m => m.ReportsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'monitoring',
    loadChildren: () => import('./pages/monitoring/monitoring.module').then( m => m.MonitoringPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'open-report',
    loadChildren: () => import('./pages/open-report/open-report.module').then( m => m.OpenReportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'monatsblatt',
    loadChildren: () => import('./pages/monatsblatt/monatsblatt.module').then( m => m.MonatsblattPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'statement',
    loadChildren: () => import('./pages/case/statement/statement.module').then( m => m.StatementPageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
