import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import {ToastService} from "./services/toast/toast.service";
import {AuthService} from "./services/authentication/auth/auth.service";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isToastOpen = false;
  message = "";

  constructor(private menu: MenuController, private toastService: ToastService, private authService: AuthService) {
    this.toastService.toastSubscription().subscribe((toast: string) => {
      this.setToastOpen(true);
      this.message = toast;
    });
  }

  closeMenu(){
    this.menu.close();
  }

  logout() {
    this.menu.close();
    this.authService.logout();
  }

  setToastOpen(isOpen: boolean) {
    this.isToastOpen = isOpen;
  }
}
